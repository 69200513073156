import React, { useState, useEffect } from 'react'
import Layout from 'gatsby-theme-kn/src/components/modules/Layout'
import Image from 'gatsby-theme-kn/src/components/atoms/Image'
import Link from 'gatsby-theme-kn/src/components/atoms/Link'
import Richtext from 'gatsby-theme-kn/src/components/atoms/Richtext'
import OneColWrapper from 'gatsby-theme-kn/src/components/technical/OneColWrapper'
import SEO from 'gatsby-theme-kn/src/components/modules/Seo'
import { useTranslation } from 'react-i18next'
import { Listbox } from '@headlessui/react'
import { graphql } from 'gatsby'

const ProductOverviewTemplate = ({ data }) => {
  const { t } = useTranslation()

  const [selectedCategory, setSelectedCategory] = useState({
    name: t('product_overview_page.allCategories'),
    value: 'all',
  })
  const [allProducts, setAllProducts] = useState([])

  const node = data.entry
  const products = data.products.nodes
    .sort((a, b) => {
      return a.name.localeCompare(b.name)
    })
    .map((product) => {
      const content = JSON.parse(product.content)
      return { ...product, content }
    })
    .sort((a, b) => {
      return a.content.name - b.content.name
    })
  const content = JSON.parse(node.content)
  const locale = node?.full_slug.split('/')[0]
  const alternates = node?.alternates
  const name = node.name
  const datasource = data.tags.edges

  useEffect(() => {
    products && setAllProducts([...products])
    return () => {
      setAllProducts([])
      setSelectedCategory({
        name: `${t('product_overview_page.allCategories')}`,
        value: 'all',
      })
    }
  }, [])

  useEffect(() => {
    setSelectedCategory({
      name: `${t('product_overview_page.allCategories')}`,
      value: 'all',
    })
  }, [locale])

  let tagList = []
  products?.forEach((node) => {
    node.tag_list.forEach((tag) => tagList.push(tag))
  })

  const filterProducts = (category, products) => {
    if (category.value !== 'all') {
      const filteredProducts = products.filter((item) => {
        if (item.tag_list.includes(category.value)) {
          return item
        }
        return null
      })
      setAllProducts([...filteredProducts])
    } else {
      setAllProducts([...products])
    }
  }

  const handleChange = (e) => {
    console.log(e)
    setSelectedCategory(e)
    filterProducts(e, products)
  }

  return (
    <div className="product-overview">
      <Layout locale={locale} alternates={alternates}>
        <SEO content={content} name={name} og_locale={locale} />
        <section className="skewed-block skewed-block-on-gray">
          <div className="skewed-block-space"></div>
          <div className="skewed-block-effect"></div>
        </section>
        <section className="item-grid-block">
          <div className="item-grid-block-container">
            <div className="item-grid-block-wrapper">
              <div className="item-grid-block-header">
                <h1>{name}</h1>
              </div>
              <div className="header-block">
                <div className="header-block-content item-grid-block-header-filter">
                  <div className="dropdown">
                    <Listbox
                      value={selectedCategory.value}
                      onChange={handleChange}
                    >
                      <Listbox.Button className="btn btn-outline-secondary dropdown-toggle">
                        {selectedCategory.name}
                      </Listbox.Button>
                      <Listbox.Options className="dropdown-menu show">
                        <>
                          <Listbox.Option
                            className="dropdown-item"
                            value={{
                              name: t('product_overview_page.allCategories'),
                              value: 'all',
                            }}
                          >
                            {`${t('product_overview_page.allCategories')}`}
                          </Listbox.Option>
                          {datasource?.map(
                            (source, index) =>
                              tagList.includes(source.node.value) && (
                                <Listbox.Option
                                  className="dropdown-item"
                                  key={index}
                                  value={{
                                    name: source.node.name,
                                    value: source.node.value,
                                  }}
                                >
                                  {source.node.name}
                                </Listbox.Option>
                              )
                          )}
                        </>
                      </Listbox.Options>
                    </Listbox>
                  </div>
                </div>
              </div>
              <div className="item-grid-block-body" id="product-filter-wrapper">
                {allProducts?.length > 0 &&
                  allProducts.map((item, index) => (
                    <div
                      key={index}
                      className={`${
                        allProducts.length < 6
                          ? 'item-grid-block-item-primary'
                          : (index + 1) % 6 == 0 || (index + 1) % 6 == 1
                          ? 'item-grid-block-item-primary'
                          : 'item-grid-block-item'
                      }`}
                    >
                      <Link
                        link={`/${item.full_slug}`}
                        className="item-grid-block-item-content"
                      >
                        <div className="item-grid-block-item-image">
                          <Image
                            className="img-fluid item-grid-block-item-image-big"
                            image={item.content.isolated_image}
                            imageWidth="540"
                            imageHeight="324"
                            fillColor="FFFFFF"
                            alt={`${item.name} - ${item.content.headline}`}
                          />
                          <Image
                            className="img-fluid item-grid-block-item-image-small"
                            image={item.content.isolated_image}
                            imageWidth="255"
                            imageHeight="255"
                            fillColor="FFFFFF"
                            alt={`${item.name} - ${item.content.headline}`}
                          />
                        </div>
                        <div className="item-grid-block-item-text">
                          <h4>
                            <span>{item.name}</span>
                          </h4>
                          <p>{item.content.headline}</p>
                        </div>
                        {item.content.main_award?.uploads?.length > 0 &&
                          item.content.main_award.uploads.map((item, index) => (
                            <div
                              className="item-grid-block-item-eyecatcher"
                              key={index}
                            >
                              <div
                                className="item-grid-block-item-eyecatcher-content"
                                style="background-image: url('{{ preview_url }}')"
                              >
                                <span>{item.content.title}</span>
                              </div>
                            </div>
                          ))}
                      </Link>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </section>
        <section
          className="product-category-description-block"
          style={{ background: '#f6f6f6' }}
        >
          <OneColWrapper block={{}}>
            <Richtext text={content.description} />
          </OneColWrapper>
        </section>
      </Layout>
    </div>
  )
}

export const query = graphql`
  query ProductOverviewTemplateQuery($id: String!, $tagList: [String]) {
    entry: storyblokEntry(id: { eq: $id }) {
      content
      name
      full_slug
      alternates {
        full_slug
      }
    }
    products: allStoryblokEntry(
      filter: {
        tag_list: { in: $tagList }
        field_component: { eq: "product_page" }
      }
    ) {
      nodes {
        name
        full_slug
        content
        tag_list
      }
    }
    tags: allStoryblokDatasourceEntry(
      filter: { data_source: { eq: "product-categories" } }
    ) {
      edges {
        node {
          value
          name
        }
      }
    }
  }
`

export default ProductOverviewTemplate
